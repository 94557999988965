<template>
  <el-container>
    <el-header class="header" :style="{backgroundColor: configset.framecolor}">
      <div class="flex flex-sb font-20" style="color: white">
        <div>
          <i class="el-icon-chat-dot-square iconft"></i>
          <span>{{ robotinfo.projname }}智能客服</span>
        </div>
        <div class="flex">
          <div>
            <i class="el-icon-user iconft"></i>
            <span><a @click="toclient" target="_blank">人工服务</a></span>
          </div>
          <div style="margin-left: 20px;color: #ff9900">
            <i class="el-icon-s-custom iconft"></i>
            <span><a @click="toRobot" target="_blank">自助咨询</a></span>
          </div>
          <div style="margin-left: 20px">
            <i class="el-icon-chat-dot-square iconft"></i>
            <span><a @click="leavingMessage" target="_blank">我要留言</a></span>
          </div>
        </div>
      </div>
    </el-header>
    <el-container>
      <el-aside width="200px">
        <div class="pad15">
          <el-avatar
            :src="robotinfo.robotimgurl"
            shape="square"
            :size="170"
          ></el-avatar>
          <div>
            <div>
              <span>智能机器人：</span>
              <span>{{ robotinfo.robotname }}</span>
            </div>
          </div>
        </div>
      </el-aside>
      <el-main class="main">
        <div class="message-record" id="msgList">
          <template v-for="item in messageConfig.data">
            <div :class="['message-row',{self: type === item.type}]">
              <div>
                <span>{{ item.nickname }}</span>
                <span>{{ dateToString(item.sendTime) }}</span>
              </div>
              <div class="message-content">
                <div v-html="item.content"></div>
              </div>
            </div>
          </template>
        </div>
        <div class="links">
          <template v-for="item in quickLinks">
            <div class="link" @click="clicklink(item.keyword,item.content)"><a>{{ item.keyword }}</a>
            </div>
          </template>
        </div>
        <editor @send="webSocketConfig.onSend" v-model="content"
                placeholder="按 Ctrl + Enter 发送"></editor>
        <div class="bottom-title">
          <span><el-image style="width: 16px;vertical-align: text-top;margin-right: 4px;"
                          src="/images/icon-r.png"></el-image></span>
          <span><a href="https://www.aiocs.cn" target="_blank">默蓝客服系统由{{
              $store.state.setting.technicalSupport
            }}提供技术支持</a></span>
        </div>
      </el-main>
      <el-aside width="200px" class="right-aside">
        <div class="title font-18">热门问题</div>
        <div style="padding:10px 20px;line-height: 30px;font-size: 14px;">
          <template v-for="(item,index) in hotissuesList">
            <div @click="sendHotissues(item.keyword,item.content)"><a>{{ index + 1 }}.
              {{ item.keyword }}</a>
            </div>
          </template>
        </div>
      </el-aside>
    </el-container>
    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form ref="dataForm" :rules="rules" :model="temp" label-position="left" label-width="100px"
               style="width: 500px; margin-left:50px;">
        <el-form-item label="电话" prop="mobile">
          <el-input v-model="temp.mobile" type="text" placeholder="请输入电话"/>
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="temp.email" type="text" placeholder="请输入邮箱"/>
        </el-form-item>
        <el-form-item label="QQ" prop="qq">
          <el-input v-model="temp.qq" type="text" placeholder="请输入QQ号码"/>
        </el-form-item>
        <el-form-item label="留言内容" prop="content">
          <el-input v-model="temp.content" type="textarea" placeholder="请输入留言内容"/>
        </el-form-item>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          保存
        </el-button>
      </div>
    </el-dialog>
  </el-container>
</template>

<script>
import editor from "@PAGE/common/ChatArea";
import {addLeaveMessage, getAutoReply, getrobotinfo} from "@/apis/robot";
import {getsomequicklinks} from "@/apis/quick-links";
import {decryptTokenkey, getConfigset, saveRobotMsgReload} from "@/apis/webstocket";
import asyncValidator from "@/resources/js/async-validator";

export default {
  name: "index",
  components: {
    Editor: editor
  },
  data() {
    return {
      configset: {
        framecolor: null
      },
      type: 1,
      projid: 0,
      robotinfo: {
        projid: '',
        robotname: null,
        projname: null,
        robotimgurl: '/images/robot.jpg',
        welcomemessage: null
      }, hotissuesList: [{
        keyword: "",
        content: "",
      }]
      , quickLinks: []
      , robotmsgReload: {
        projid: '',
        content: null,
        replaycontent: null,
        createtime: null,
      },
      content: null,
      messageConfig: {
        data: [],
        loadData: () => {
        },
      }, temp: {
        projid: '',
        mobile: '',
        email: '',
        qq: '',
        content: ''
      },
      dialogFormVisible: false,
      dialogStatus: '',
      textMap: {
        create: '添加留言'
      },
      webSocketConfig: {
        webSocket: null,
        open: () => {
          if (this.webSocketConfig.webSocket) {
            this.webSocketConfig.webSocket.onmessage = (message) => {
              this.messageConfig.data.push(message);
            }
          }
        },
        onSend: (msg) => {
          let this_ = this;
          this.robotmsgReload.content = msg;
          let message = {
            type: 1,
            content: msg,
            nickname: '我',
            totype: 2,
            sendTime: new Date().getTime(),
          };
          this.messageConfig.data.push(message);
          let data = {
            "projid": this.projid,
            "msgtype": 1,
            "content": msg
          }
          saveRobotMsgReload(data);
          this.content = '';
          getAutoReply(this.robotmsgReload).then(res => {
            let message = {
              type: 2,
              content: res.retdata.replaycontent,
              nickname: res.retdata.robotname,
              totype: 1,
              sendTime: new Date().getTime(),
            };
            this_.messageConfig.data.push(message);
            let data = {
              "projid": this_.projid,
              "msgtype": 2,
              "replaycontent": res.retdata.replaycontent
            }
            setTimeout(function () {
              saveRobotMsgReload(data);
            }, 500);
          })
        },
      }, rules: {
        mobile: [{required: true, validator: asyncValidator.validPhone, trigger: 'change'}],
        email: [{required: true, type: "email", message: '请输入正确的邮箱', trigger: 'change'}],
        content: [{required: true, message: '请输入留言内容', trigger: 'change'}],
        qq: [{required: true, message: '请输入QQ号码', trigger: 'change'}]
      },
    }
  }, created() {
    window.clickSend = this.clickSend
  },
  updated() {
    // 聊天定位到底部
    let ele = document.getElementById('msgList');
    ele.scrollTop = ele.scrollHeight;
  },
  mounted() {
    this.decryptTokenkey();
  },
  methods: {
    getsomequicklinks() {
      let data = {
        projid: this.temp.projid
      }
      getsomequicklinks(data).then(res => {
        const {count = 0} = res;
        this.quickLinks = res.retdata;
      })
    },
    getrobotinfo() {
      getrobotinfo(this.robotinfo).then(res => {
        this.robotinfo = res.retdata.robotInfo;
        this.hotissuesList = res.retdata.hotissuesList;
        let message = {
          type: 2,
          content: res.retdata.robotInfo.welcomemessage,
          nickname: res.retdata.robotInfo.robotname,
          totype: 1,
          sendTime: new Date().getTime(),
        };
        this.messageConfig.data.push(message);
      })
    }, clickSend(msg) {
      let this_ = this;
      console.log(msg)
      this.robotmsgReload.content = msg;
      let message = {
        type: 1,
        content: msg,
        nickname: '我',
        totype: 2,
        sendTime: new Date().getTime(),
      };
      this.messageConfig.data.push(message);
      let data = {
        "projid": this.projid,
        "msgtype": 1,
        "content": msg
      }
      saveRobotMsgReload(data);
      this.content = '';
      getAutoReply(this.robotmsgReload).then(res => {
        let message = {
          type: 2,
          content: res.retdata.replaycontent,
          nickname: res.retdata.robotname,
          totype: 1,
          sendTime: new Date().getTime(),
        };
        this_.messageConfig.data.push(message);
        let data = {
          "projid": this_.projid,
          "msgtype": 2,
          "replaycontent": res.retdata.replaycontent
        }
        setTimeout(function () {
          saveRobotMsgReload(data);
        }, 500);
      })
    }, sendHotissues(msg, content) {
      console.log(content)
      let message = {
        type: 1,
        content: msg,
        nickname: '我',
        totype: 2,
        sendTime: new Date().getTime(),
      };
      this.messageConfig.data.push(message);
      let data = {
        "projid": this.projid,
        "msgtype": 1,
        "content": msg
      }
      saveRobotMsgReload(data);
      message = {
        type: 2,
        content: content,
        nickname: this.robotinfo.robotname,
        totype: 1,
        sendTime: new Date().getTime(),
      };
      this.messageConfig.data.push(message);
      data = {
        "projid": this.projid,
        "msgtype": 2,
        "replaycontent": content
      }
      setTimeout(function () {
        saveRobotMsgReload(data);
      }, 500);
    }, resetTemp() {
      this.temp = {
        mobile: '',
        email: '',
        qq: '',
        content: ''
      }
    }, handleCreate() {
      this.resetTemp()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    }, createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          addLeaveMessage(this.temp).then(res => {
            this.$message.success(res.retDesc);
            this.dialogFormVisible = false;
          });
        }
      })
    }, decryptTokenkey() { //地址栏参数解码
      let data = {
        tokenkey: this.$route.query.projid
      }
      decryptTokenkey(data).then(res => {
        console.log("res", res)
        this.temp.projid = res.retdata.projid;
        this.projid = res.retdata.projid;
        this.robotinfo.projid = res.retdata.projid;
        this.robotmsgReload.projid = res.retdata.projid;
        this.webSocketConfig.open();
        this.getrobotinfo();
        this.getsomequicklinks();
        this.getConfigset();
        console.log("proj,", this.robotinfo.projid)

      })
    }, toclient() {
      this.$router.push("/client?tokenkey=" + this.getEcodeUrl(this.$route.query.tokenkey));
    }, toRobot() {
      this.$router.push("/vclient/robot?tokenkey=" + this.getEcodeUrl(this.$route.query.tokenkey));
    }, leavingMessage() {
      this.$router.push("/leavingMessage?tokenkey=" + this.getEcodeUrl(this.$route.query.tokenkey));
    }, clicklink(msg, content) {
      let message = {
        type: 1,
        content: msg,
        nickname: '我',
        totype: 2,
        sendTime: new Date().getTime(),
      };
      this.messageConfig.data.push(message);
      let data = {
        "projid": this.projid,
        "msgtype": 1,
        "content": msg
      }
      saveRobotMsgReload(data);
      message = {
        type: 2,
        content: content,
        nickname: this.robotinfo.robotname,
        totype: 1,
        sendTime: new Date().getTime(),
      };
      this.messageConfig.data.push(message);
      data = {
        "projid": this.projid,
        "msgtype": 2,
        "replaycontent": content
      }
      setTimeout(function () {
        saveRobotMsgReload(data);
      }, 500);
    }, getConfigset() {
      let data = {
        projid: this.temp.projid
      }
      getConfigset(data).then(res => {
        this.configset = res.retdata;
      })
    }, getEcodeUrl(url) {
      if (url) {
        // url地址栏中特殊字符转义
        url = url
          .replace(/\=/g, "%3D")
          .replace(/\+/g, "%2B")
          .replace(/[\s]/g, "%2F")
          .replace(/\?/g, "%3F")
          .replace(/\#/g, "%23")
          .replace(/\&/g, "%26");
      }
      return url;
    }
  }
}
</script>

<style scoped lang="scss">

.el-header, .el-footer {
  line-height: 60px;
}

.el-aside {
  background-color: #D3DCE6;
}

.el-container {
  height: 100%;
}

.right-aside {
  .title {
    line-height: 50px;
    text-align: center;
    border-bottom: 1px #eee solid;
  }
}

.el-main.main ::v-deep {
  position: relative;
  padding: 0;
  height: 100%;
  $editor-height: 230px;

  .message-record {
    box-sizing: border-box;
    background-color: white;
    height: calc(100% - #{$editor-height});
    overflow: auto;
    padding: 10px;

    .message-row {
      box-sizing: border-box;
      margin-bottom: 15px;

      &.self {
        text-align: right;

        .message-content {
          &:before {
            left: 100%;
            border: 5px solid transparent;
            border-left-color: #F8f8f8;
          }
        }
      }

      .message-content {
        box-sizing: border-box;
        padding: 0 10px;
        display: inline-block;
        line-height: 2.5;
        border: 1px #eee solid;
        background-color: #F8f8f8;
        border-radius: 3px;
        position: relative;
        margin-top: 5px;

        &:before {
          box-sizing: border-box;
          content: '';
          top: 10px;
          position: absolute;
          right: 100%;
          border: 6px solid transparent;
          border-right-color: #F8f8f8;
        }
      }
    }
  }

  .el-tiptap-editor {
    height: $editor-height;
    border-radius: unset;

    .ProseMirror {
      min-height: 100%;
    }

    .el-tiptap-editor__menu-bar:before {
      margin: 0;
    }

    & > .el-tiptap-editor__content {
      padding: 10px;
      flex: 1;
    }

    .border-top-radius {
      border-radius: unset;
    }
  }
}

.iconft {
  font-size: 20px;
  padding-right: 10px;
}

.links {
  display: flex;
  background-color: white;
  flex-wrap: wrap;
}

.link {
  padding: 2px 10px;
  border: 1px solid;
  border-radius: 24px;
  margin: 2px 2px 5px 5px;
  color: #969798;
}

.bottom-title {
  color: black;
  text-decoration: none;
  position: absolute;
  bottom: 41px;
  font-size: 12px;
  background-color: rgb(234, 235, 236);
  width: 100%;
  text-align: center;
  height: 25px;
  line-height: 25px;
}
</style>
